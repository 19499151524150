import Stripe from "../components/Stripe"
import remerasblancas from '../images/remerastienda/remeras_blancas.png'
import remeranegrasofit from '../images/remerastienda/sofi_negra.png'
import remeranegraamanda from '../images/remerastienda/amanda_negra.png'
import tallesremera from '../images/remerastienda/talles_remeras.jpeg'
import remeyogui1 from '../images/remerastienda/REMERAYOGUI1.jpg'
import remeyogui2 from '../images/remerastienda/REMERAYOGUI2.jpg'

export default function StoreTShirts (){
	return (
		<>
			<Stripe title="Tienda - Remeras"/>
			<h3 className="pt-5 text-4xl text-center font-ab">AGOTADAS</h3>
			<h3 className="pt-5 text-3xl text-center font-ab">REMERAS YOGUIS</h3>
			<br/>
			<p className="text-lg text-left font-ar md:ml-5">&Eacute;stas remeras son una colaboraci&oacute;n con <a href="https://www.instagram.com/bebebart" className="text-dark-pink hover:text-light-pink">@bebebart</a> y toda mi comunidad de Instagram, hace un tiempo les ped&iacute; por esa red que me env&iacute;en fotos haciendo yoga, y seleccionamos las dos finalistas en una votaci&oacute;n masiva.</p>
			<p className="text-lg text-left font-ar md:ml-5">&Eacute;ste es el resultado y me alegra mucho compartirlo con ustedes.</p>
			<br/>
			<div className="w-full bg-grey md:min-h-content">
				<div className="flex flex-col md:flex-row md:flex-wrap md:justify-around md:mt-5">
					<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto remera" src={remeyogui1}></img></div>				
					<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto remera" src={remeyogui2}></img></div>				
					<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto remera" src={remeranegrasofit}></img></div>				
					<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto remera" src={remeranegraamanda}></img></div>				
					<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto remera" src={remerasblancas}></img></div>				
					<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto remera" src={tallesremera}></img></div>				
				</div>
			</div>
		</>
	)
}
