import Stripe from "../components/Stripe";
import giftcard from '../images/giftcard.png'
import carrito from '../images/carrito.png'

export default function StoreGiftCard (){
	return (
		<>
			<Stripe title="Tienda - Gift Card"/>
			<br/>
			<p className="text-lg text-left font-ar md:ml-5">Un mes de clases de yoga y meditaci&oacute;n <span className="font-ab">online</span>, para ser usados entre febrero y noviembre de 2022</p>
			<p className="text-lg text-left font-ar md:ml-5">No es necesario tener experiencia.</p>
			<br/>
			<p className="pt-3 pb-3 pl-10 pr-10 mt-3 ml-0 text-center text-white align-middle md:ml-3 w-80 font-msb bg-dark-pink hover:text-light-pink">
				<a href="https://mpago.la/2jHFXHF">Pagar con MercadoPago</a>
                <img className="float-right mt-1.5" src={carrito} width="16" alt="carrito de compras"></img>
            </p>
			<div className="w-full bg-grey md:min-h-content">
				<div className="flex flex-col md:flex-row md:flex-wrap md:justify-around md:mt-5">
				<div className="m-5 md:w-halfScreen"><img className="ml-auto mr-auto" alt="gift card" src={giftcard}></img></div>				
				</div>
			</div>
		</>
	);
}
