import Stripe from "../components/Stripe";
import formacion from '../images/formacion.jpg';
import wsp from '../images/wsp.png';

export default function Formation() {
    return (
        <>
            <Stripe title="Formaci&oacute;n"></Stripe>
            <div className="w-full md:min-h-content pt-4 pb-10">
                <img className="ml-auto mr-auto md:w-1/2" alt="imagen de entrega de diplomas" src={formacion}></img>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5">Junto a la Asociaci&oacute;n Bonaerense de Yoga te ofrecemos dos niveles de formaci&oacute;n, tanto para dar clases como para profundizar tu pr&aacute;ctica personal.</p>
                <p className="ml-2 text-lg font-ar md:ml-5">Cada uno de los niveles dura 9 meses.</p>
                <p className="ml-2 text-lg font-ar md:ml-5">No se abona matr&iacute;cula ni inscripci&oacute;n.</p>
                <hr className="text-dark-pink mt-4"/>
                <h3 className="ml-2 mt-4 text-2xl font-ab md:ml-5 bg-dark-pink text-white p-2 w-fit">Instructorado</h3>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5">Curso de Formaci&oacute;n Profesional para capacitarte como Instructora de Yoga Integral, t&iacute;tulo otorgado por la Asociaci&oacute;n Bonaerense de Yoga.</p>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5"><span className="font-ab">Cursada:</span> Clases libres (con un m&iacute;nimo de 2 veces por semana) m&aacute;s un seminario el tercer s&aacute;bado de cada mes de 8 a 13hs.</p>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5"><span className="font-ab">Duraci&oacute;n:</span> 9 meses ONLINE (desde cualquier lugar del mundo) o PRESENCIAL en Salta Capital, Argentina.</p>
                <p className="ml-2 mt-4 text-lg font-ab md:ml-5">No es necesario tener experiencia.</p>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5">Contenidos b&aacute;sicos:</p>
                <ul className="list-disc ml-12">
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">T&eacute;cnicas para brindar una adecuada clase de yoga: saludos, asanas, krishas, streching, yoga art&iacute;stico, serie de Rishikesh.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Anatom&iacute;a del cuerpo humano: m&uacute;sculos, huesos, articulaciones, sistema glandular.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">La respiraci&oacute;n.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Pranayama.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Meditaci&oacute;n.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Historia del Yoga.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Distintos tipos de Yoga.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Introducci&oacute;n a la psicolog&iacute;a del Yoga Integral.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Nociones de Ayurveda y Yoga Terapia.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Lineamientos para el armado de clases.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">An&aacute;lisis, alineaci&oacute;n y ejecuci&oacute;n de asanas.</li>
                </ul>
                <hr className="text-dark-pink mt-4"/>
                <h3 className="ml-2 mt-4 text-2xl font-ab md:ml-5 bg-dark-pink text-white p-2 w-fit">Profesorado</h3>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5">Es el segundo nivel de formaci&oacute;n, por lo tanto requiere tener el instructorado aprobado por la misma instituci&oacute;n.</p>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5"><span className="font-ab">Cursada:</span> Clases libres (con un m&iacute;nimo de 3 horas por semana) m&aacute;s un seminario el cuarto s&aacute;bado de cada mes de 8 a 13 hs o a convenir.</p>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5"><span className="font-ab">Duraci&oacute;n:</span>9 meses ONLINE (desde cualquier lugar del mundo) o PRESENCIAL en Salta Capital, Argentina.</p>
                <p className="ml-2 mt-4 text-lg font-ar md:ml-5">Contenidos b&aacute;sicos:</p>
                <ul className="list-disc ml-12">
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">T&eacute;cnicas de Meditaci&oacute;n.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Pranayama avanzado.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Yoga Sutras de Pata&ntilde;jali.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Fundamentos del budismo.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">Fundamentos del hindu&iacute;smo.</li>
                    <li className="ml-2 mt-4 text-lg font-ar md:ml-5">An&aacute;lisis, ejecuci&oacute;n y alineaci&oacute;n de asanas.</li>
                </ul>
                <hr className="text-dark-pink mt-4"/>
                <p className="w-80 font-msb bg-dark-pink text-white hover:text-light-pink ml-3 mt-3 pt-3 pb-3 pr-4 pl-4 text-center align-middle2">
                    <img className="float-left mt-1 mr-1" src={wsp} width="16" alt="logo de whatsapp"></img>
                    <a href="https://wa.me/message/FGLZHXAPC7OTK1" target="_blank" rel="noreferrer">Quiero recibir m&aacute;s informaci&oacute;n</a>
                </p>
            </div>
        </>
    )
}