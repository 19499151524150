import Stripe from '../components/Stripe'
import email from '../images/email_white.png'
import wsp from '../images/wsp.png'

export default function Contact() {
    return (
        <>
            <Stripe title="Contacto"></Stripe>
            <div className="min-h-content w-full flex flex-col items-center">
                <div className="min-h-content w-full md:w-4/6 p-5 md:pt-10 bg-grey">
                    <p className="font-ar text-xl text-black">Ac&aacute; estoy para responder tus dudas y compartirte la informaci&oacute;n que desees.</p>
                    <p className="font-ar text-xl text-black">Enviame un mensaje y te responder&eacute; lo antes posible.</p>
                    <br/>
                    <br/>
                    <p className="font-ar text-xl text-black">Gracias por ponerte en contacto.</p>
                    <br/>
                    <br/>
                    <br/>
                    <div className='flex flex-row gap-2'>
                            <p className="font-msb bg-dark-pink text-white hover:text-light-pink ml-3 mt-3 pt-3 pb-3 pr-4 pl-4 text-center align-middle2">
                                <a className="text-center" href="https://wa.me/message/FGLZHXAPC7OTK1" target="_blank" rel="noreferrer">
                                    <img src={wsp} width="40" alt="logo de whatsapp"></img>
                                </a>
                            </p>
                            <p className="font-msb bg-dark-pink text-white hover:text-light-pink ml-3 mt-3 pt-3 pb-3 pr-4 pl-4 text-center align-middle2">
                                <a className="text-center" href="mailto:yogaconursula@gmail.com?subject=Consulto por..." target="_blank" rel="noreferrer">
                                    <img src={email} width="40" alt="logo de email"></img>
                                </a>
                            </p> 
                    </div>
                </div>
            </div>
        </>
    )
}