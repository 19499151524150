import Social from '../components/Social'

export default function Footer() {
    return (
        <div className="min-h-40 bg-footer">
            <div className="min-h-40 pt-14 pb-14 md:pt-0 md:pb-0 flex flex-col md:flex-row items-center justify-center bg-black bg-opacity-50">
                <div className="font-msb text-white text-center md:pr-20">
                    <p>ARGENTINA</p>
                    <a href="mailto:yogaconursula@gmail.com?subject=Consulto por...">yogaconursula@gmail.com</a>
                </div>
                <div className="text-white text-center pl-20 pr-20 pb-10 pt-10 md:pt-0 md:pb-0 md:border-l md:border-r">
                    <p className="font-msb text-lg md:text-2xl">&Uacute;rsula R&iacute;os Malan</p>
                    <p className="font-ar">Yoga Integral</p>
                    <p className="font-ar">Asociaci&oacute;n Bonaerense de Yoga</p>
                </div>
                <div className="font-msb text-white text-center md:pl-20 flex flex-col items-center">
                    <p>Tel&eacute;fono: +549 221-4779134</p>
                    <Social className="pt-2" theme="white"/>
                </div>
            </div>
        </div>
    )
}