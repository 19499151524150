import { useEffect, useRef, useState } from "react"
import BeatLoader from "react-spinners/BeatLoader"
import { Link, useLocation } from "wouter"
import Stripe from "../components/Stripe"
import useLogout from "../util/useLogout"
import useRichRendered from '../util/useRichRendered'

export default function Clazz({params: {slug, order}}) {
    const videoElement = useRef(null)
    const logout = useLogout()
    const [, navigate] = useLocation();

    const [clazz, setClazz] = useState(null)
    const [error, setError] = useState(false)
    const [getting, setGetting] = useState(true)
    const [courseName, setCourseName] = useState(null)
    const descriptionText = useRichRendered(clazz, "description", false)
    const [previous, setPrevious] = useState(null)
    const [next, setNext] = useState(null)

    useEffect(() => {
        const jwt = localStorage.getItem("jwt")
        fetch(`${process.env.REACT_APP_API_URL}/fullCourse/${slug}${jwt ? `/${jwt}`:''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json()).then(r => {
            if (r.logout) {
                logout()
                navigate('/')
            } else {
                if (r.ok) {
                    const auxClazz = r.course.clases.find(c => Number(c.fields.orden) === Number(order))
                    const orders = r.course.clases.map(c => Number(c.fields.orden))
                    const idx = orders.indexOf(Number(order))
                    if (idx > 0) {
                        setPrevious(orders[idx-1])
                    } else {
                        setPrevious(null)
                    }
                    if (idx < (orders.length - 1)) {
                        setNext(orders[idx+1])
                    } else {
                        setNext(null)
                    }

                    if (auxClazz) {
                        setClazz({
                            title: auxClazz.fields.titulo,
                            description: auxClazz.fields.descripcion,
                            hasVideo: auxClazz.fields.tieneVideo,
                            order: Number(auxClazz.fields.orden),
                            publicVideoUrl: auxClazz.fields.publicVideoUrl,
                            _id: auxClazz.sys.id
                        })
                    } else {
                        setError(`No existe la clase ${order} dentro de este curso.`)
                    }
                    setCourseName(r.course.nombre)
                } else {
                    setError(r.message)
                }
            }
        }).catch(e => {
            setError("Ocurrió un error buscando los datos del curso. Intentalo nuevamente.")
        }).finally(() => {setGetting(false)})
    }, [logout, navigate, slug, order])

    useEffect(() => {
        clazz && videoElement && videoElement.current && videoElement.current.load();
    }, [clazz]);

    return (
        <div key={order}>
            <Stripe title={`Curso${courseName ? ": " + courseName : ""}`}></Stripe>
            <div className="flex flex-col items-start md:items-start p-3">
                {getting && <BeatLoader color="#FF7272" loading={getting} size={20}></BeatLoader>}
                {!getting && error && <p className="font-ar text-lg text-black">{error}</p>}
                {!getting && !error &&
                    <>
                        <h5 className="font-ab text-3xl ml-auto mr-auto">{clazz.order} - {clazz.title}</h5>
                        {descriptionText && 
                            <div className="md:pl-20 md:pr-20 pb-5 font-ar">
                                {descriptionText}
                            </div>
                        }
                        {clazz.hasVideo &&
                            <video ref={videoElement} className="m-auto" controls controlsList="nodownload">
                                <source src={`${clazz.publicVideoUrl ? clazz.publicVideoUrl : `${process.env.REACT_APP_API_URL}/video/${clazz._id}/${localStorage.getItem("jwt")}`}`} type="video/mp4">
                                </source>
                            </video>
                        }
                        {(next || previous) &&
                            <div className="mr-auto ml-auto flex flex-row mt-5">
                                {previous && <p className="font-ab text-xl text-black"><Link className="font-ab text-dark-pink hover:text-light-pink" to={`/clases/${slug}/${previous}`}>Anterior</Link></p>}
                                {(next && previous) && <p className="font-ab ml-2 mr-2">-</p>}
                                {next && <p className="font-ab text-xl text-black"><Link className="font-ab text-dark-pink hover:text-light-pink" to={`/clases/${slug}/${next}`}>Siguiente</Link></p>}
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}