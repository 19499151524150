import { useEffect, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span className="font-ab">{text}</span>,
      [MARKS.ITALIC]: text => <span className="font-ai">{text}</span>
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (<p className="text-lg p-3">{children}</p>)
        },
        [BLOCKS.HEADING_1]: (node, children) => <h1 className="text-6xl p-3">{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-5xl p-3">{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-4xl p-3">{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4 className="text-3xl p-3">{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5 className="text-2xl p-3">{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <p className="text-xl p-3">{children}</p>,
        [BLOCKS.QUOTE]: (node, children) => <p className="font-ai ml-5">{children}</p>,
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            if (node.data.target.fields.file.contentType.indexOf("image") !== -1) {
                return (<img src={node.data.target.fields.file.url} className="m-auto" alt="imagen ilustrativa"/>)
            } else if (node.data.target.fields.file.contentType.indexOf("video") !== -1) {
                return (<video className="m-auto" controls controlsList="nodownload"><source src={node.data.target.fields.file.url} type={node.data.target.fields.file.contentType}></source></video>)
            }
        },
        [BLOCKS.HR]: (node, children) => <hr className="text-dark-pink"/>,
        [INLINES.HYPERLINK]: (node, children) => <a className="text-dark-pink text-lg underline hover:no-underline" href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>,
        [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc ml-12">{ children }</ul>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li className="m-0 p-0 -mt-5">{children}</li>
    }
};

function useRichRendered(post, fieldName, preview) {
    const [ postText, setPostText ] = useState(null)
    
    useEffect(() => {
        const setRenderedText = () => {
            if (post) {
                let toRender;
                if (preview) {
                    toRender = {nodeType: "document", content: []}
                    let i = 0;
                    while (i < post[fieldName].content.length && toRender.content.length < 1) {
                        if (post[fieldName].content[i].nodeType === 'paragraph') {
                            toRender.content.push(post[fieldName].content[i])
                        }
                    }
                } else {
                    toRender = post[fieldName]
                }
                setPostText(documentToReactComponents(toRender, options))
            }
        }
        setRenderedText()
    }, [post, fieldName, preview])

    return postText;
}

export default useRichRendered;