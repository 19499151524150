import { useMemo } from "react";
import { Link } from 'wouter';
import useRichRendered from '../util/useRichRendered';

export default function BlogPostPreview({className, post }) {
    const date = useMemo(() => 
        new Date(Date.parse(post._createdAt))
    , [post])

    const fullDay = useMemo(() => 
        new Intl.DateTimeFormat('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).format(date)
    , [date])

    const fullHour = useMemo(() => 
        new Intl.DateTimeFormat('es-AR', {
            minute: '2-digit',
            hour: '2-digit'
        }).format(date)
    , [date])

    const postText = useRichRendered(post, "texto", true)

    return (
        <div className={`bg-grey w-11/12 md:w-32p ml-1 mr-1 mb-2 ${className}`}>
            <p className="font-ar p-3 text-base">{fullDay} - {fullHour}</p>
            <h2 className="font-msb p-5 pt-1 text-xl text-black hover:text-dark-pink"><Link href={`/blog/post/${post.slug}`}>{post.titulo}</Link></h2>
            {post.imagen && <img className="w-1/4 m-auto mb-10" src={post.imagen.fields.file.url} alt={post.imagen.fields.title}></img>}
            {postText &&
                <div className="font-ar pl-2">
                    {postText}
                </div>
            }
            <p className="font-msb p-5 text-lg text-black hover:text-dark-pink"><Link href={`/blog/post/${post.slug}`}>Leer m&aacute;s...</Link></p>
        </div>
    )
}