// Main app
import ForgotPasswordModal from '../components/ForgotPasswordModal'
import LoginModal from '../components/LoginModal'
import RegisterModal from '../components/RegisterModal'
import Content from '../layout/Content'
import Footer from '../layout/Footer'
import Header from '../layout/Header'

export default function App() {
  return (
    <>
      <Header />
      <Content />
      <Footer />
      <LoginModal />
      <RegisterModal />
      <ForgotPasswordModal />
    </>
  );
}