import { useMediaQuery } from 'react-responsive';
import { Link, useRoute } from 'wouter';
import useStore from '../store/Store';

export default function CustomLink(props) {
    const el = document.getElementById("hover-menu")
    const isNotMobile = useMediaQuery({ query: `(min-width: 768px)` });
    const toggleMenu = useStore(state => state.toggleMenu)
    const menuOpen = useStore(state => state.menuOpen)
    const [isCurrent] = useRoute(props.href);

    function toggleIfMobile() {
        if (!isNotMobile) {
            el.style["z-index"] = menuOpen ? "-1" : "1";
            toggleMenu();
        }
    }

    return (
        <Link onClick={() => toggleIfMobile()} {...props}>
            <a href={props.href} className={`focus:outline-none relative bottom-4 text-center p-4 pt-3 pb-3 md:pt-8 text-light-pink border-b-0 md:hover:text-dark-pink ${isCurrent ? 'md:text-dark-pink md:border-b-2' : 'md:text-black'}`}>{props.children}</a>
        </Link>
    )
}

