import { useEffect, useMemo } from "react"
import { Link } from "wouter"
import useStore from "../store/Store"
import useLogout from "../util/useLogout"

export default function MercadoPagoResult({params: {status}}) {
    const loggedUser = useStore(state => state.loggedUser)
    const setLoggedUser = useStore(state => state.setLoggedUser)
    const toggleLoginModal = useStore(state => state.toggleLoginModal)
    const toggleRegisterModal = useStore(state => state.toggleRegisterModal)
    const logout = useLogout()

    const isOnlineCourse = useMemo(()=> status.includes("curso-online"), [status])
    
    useEffect(() => {
        if (status.includes("procesado")) {
            if (loggedUser && isOnlineCourse) {
                fetch(`${process.env.REACT_APP_API_URL}/refresh`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("jwt")}`
                    }
                }).then(r => r.json()).then(r => {
                    if (r.logout) {
                        logout()
                    }
                    if (r.user) {
                        setLoggedUser(r.user)
                    }
                })
            }
        }
    }, [status, loggedUser, logout, setLoggedUser, isOnlineCourse])

    return (
        <div className="flex flex-col items-center p-3 text-dark-pink font-ar text-2xl mt-2">
            {status.includes("procesado") && 
                <>
                    <p className="text-center">&iexcl;Tu pago fue procesado con &eacute;xito!</p>
                </>
            }
            {status.includes("en-proceso") && 
                <>
                    <p className="text-center">Tu pago est&aacute; siendo procesado.</p>
                    {isOnlineCourse && <p className="mt-4 text-center">Cu&aacute;ndo est&eacute; acreditado, recibir&aacute;s un correo en la cuenta de email que ten&eacute;s configurada en MercadoPago.</p>}
                </>
            }
            {isOnlineCourse && loggedUser && 
                <p className="mt-4 text-center">Pod&eacute;s ver y hacer todos tus cursos entrando a <Link className="underline hover:text-light-pink" to="/mis-datos">tus datos</Link>.</p>
            }
            {isOnlineCourse && !loggedUser && 
                <>
                    <p className="mt-4 text-center">Para poder ver y hacer el curso, ten&eacute;s que tener una cuenta en el sitio, con el mismo email que ten&eacute;s configurado en MercadoPago.</p>
                    <p className="mt-2 text-center">Si ya la ten&eacute;s, <button onClick={toggleLoginModal} className="focus:outline-none underline hover:text-light-pink">inici&aacute; sesi&oacute;n</button>.</p>
                    <p className="mt-2 text-center">Sino, primero <button onClick={toggleRegisterModal} className="focus:outline-none underline hover:text-light-pink">registrate</button>.</p>
                </>
            }
            {isOnlineCourse && <p className="mt-4 text-center">Si ten&eacute;s alg&uacute;n problema para iniciar sesi&oacute;n, registrarte o ver el curso, ponete en <Link className="underline hover:text-light-pink" to="/contacto">contacto</Link> as&iacute; lo podemos resolver.</p>}
            {!isOnlineCourse && <p className="mt-4 text-center">Ponete en <Link className="underline hover:text-light-pink" to="/contacto">contacto</Link> para ver como seguir.</p>}
            <p className="mt-4 text-center">&iexcl;Gracias por tu compra!</p>
        </div>
    )
}