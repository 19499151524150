import { useEffect, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import CoursePreview from '../components/CoursePreview'
import Stripe from '../components/Stripe'

export default function Courses() {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        let fetchCourses = async () => {
            try {
                const result = await fetch(`${process.env.REACT_APP_API_URL}/courses`).then(r => r.json())
                setCourses(result)
                setLoading(false)
            } catch(e) {
                setLoading(false)
                console.log(e); setError(true)
            }
        }
         fetchCourses()
    }, [])

    return (
        <>
            <Stripe title="Clases y Cursos"></Stripe>
            <div className="pl-2 pr-2 md:pl-44 md:pr-44 pt-4 md:pt-10 pb-10 flex flex-col 
                flex-wrap md:flex-row items-center md:items-baseline justify-center">
                {loading && <BeatLoader color="#FF7272" loading={loading} size={20}></BeatLoader>}
                {!loading && !error && courses && courses.length === 0 && <p className="font-ar text-lg text-black">No se encontraron cursos.</p>}
                {courses && courses.map(course =>  <CoursePreview key={course._id} course={course}></CoursePreview> )}
                {error && <p className="font-ar text-lg text-black">Ocurri&oacute; un error mostrando esta p&aacute;gina.</p>}
            </div>
        </>
    )
}