import Stripe from "../components/Stripe";
import StoreItem from "./StoreItem";
import giftcard from '../images/giftcard.png'
import remeras from '../images/remeratienda.png';

export default function Store() {
    return (
        <>
            <Stripe title="Tienda"></Stripe>
            <div className="flex flex-col flex-wrap items-center justify-around pt-4 pb-4 pl-2 pr-2 md:pl-20 md:pr-20 md:flex-row md:items-baseline">
                <StoreItem imgAlt="Gift Card" img={giftcard} pageLink="giftcard">
                    <p className="text-base text-center font-ar">REGAL&Aacute; UNA EXPERIENCIA TRANSFORMADORA</p>
                </StoreItem>
				<StoreItem imgAlt="Remeras" img={remeras} pageLink="remeras">
					<p className="text-base text-center font-ar">REMERAS</p>
				</StoreItem>
            </div>
        </>
    )
}
