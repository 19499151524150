import { useCallback } from 'react'
import useStore from '../store/Store'

export default function useLogout() {
    const setLoggedUser = useStore(state => state.setLoggedUser)

    return useCallback(() => {
        fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("jwt")}`
            }
        }).then(r => console.log(r)).catch(e => console.log(e))
        setLoggedUser(null)
        localStorage.removeItem("jwt")
    }, [setLoggedUser])
}