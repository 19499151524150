import { Link } from "wouter";

export default function StoreItem({img, imgAlt, description, mpLink, pageLink, children}) {
    return (
        <div className="flex flex-col p-2 bg-white items-center">
			{mpLink && <a href={mpLink}>
                <img className="p-0 m-0 max-w-xs" src={img} alt={imgAlt}></img>
            </a>}
			{pageLink && <Link href={pageLink}>
                <img className="p-0 m-0 max-w-xs" src={img} alt={imgAlt}></img>
			</Link>}
            {description && <p className="font-ar text-center text-base">{description}</p>}
            {children}
        </div>
    )
}
