import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { Link, useLocation } from "wouter";
import Stripe from "../components/Stripe";

export default function CourseClases({params: {slug}}) {
    const [, navigate] = useLocation();

    const [clases, setClases] = useState([])
    const [error, setError] = useState(false)
    const [getting, setGetting] = useState(true)
    const [courseName, setCourseName] = useState(null)

    useEffect(() => {
        const jwt = localStorage.getItem("jwt")
        fetch(`${process.env.REACT_APP_API_URL}/fullCourse/${slug}${jwt ? `/${jwt}`:''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json()).then(r => {
            if (r.ok) {
                setClases(r.course.clases ? r.course.clases.map(c => 
                    ({
                        order: c.fields.orden,
                        title: c.fields.titulo,
                    })
                ).sort((a,b) => a.order - b.order) : [])

                setCourseName(r.course.nombre)
            } else {
                setError(r.message)
            }
        }).catch(e => {
            setError("Ocurrió un error buscando los datos del curso. Intentalo nuevamente.")
        }).finally(() => {setGetting(false)})
    }, [navigate, slug])

    return (
        <>
            <Stripe title={`Curso${courseName ? ": " + courseName : ""}`}></Stripe>
            <div className="flex flex-col items-center md:items-start p-3">
                {getting && <BeatLoader color="#FF7272" loading={getting} size={20}></BeatLoader>}
                {!getting && error && <p className="font-ar text-lg text-black">{error}</p>}
                {!getting && !error &&
                    <>
                        <h5 className="font-ab text-3xl ml-auto mr-auto">Clases Disponbiles</h5>
                        {
                            clases.map((c, idx) => <p className="font-abi text-xl text-black" key={idx}>✨ {c.order}: {c.title} - <Link className="font-ab text-dark-pink hover:text-light-pink" to={`/clases/${slug}/${c.order}`}>Ir</Link></p>)
                        }
                    </>
                }
            </div>
        </>
    )
}