import { Link } from "wouter"

export default function CoursePreview({className, course }) {
    return (
        <div className={`bg-grey w-11/12 md:w-32p ml-1 mr-1 mb-2 p-5 flex flex-col items-center ${className}`}>
            {course.icono && <img className="mt-2 mb-2 md:mt-8 md:mb-8" src={course.icono.fields.file.url} alt={course.icono.fields.title}></img>}
            <p className="font-ar font-base text-2xl text-center mb-5">{course.nombre}</p>
            <p className="font-ar font-base text-black text-center mb-10 md:text-sm">{course.descripcionCorta}</p>
            <p className="font-msb bg-dark-pink text-white hover:text-light-pink pt-3 pb-3 pr-10 pl-10 text-center align-middle">
                <Link href={`/cursos/${course.slug}`}>Ver M&aacute;s</Link>
            </p>
        </div>
    )
}