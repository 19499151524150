import { useEffect, useMemo, useState } from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { Link } from "wouter"
import useStore from "../store/Store"
import useRichRendered from '../util/useRichRendered'

export default function BlogPost({className, params: {slug}}) {
    const currentBlogPage = useStore(state => state.currentBlogPage)
    const [ loading, setLoading ] = useState(true)
    const [ post, setPost ] = useState(null)
    const [ error, setError ] = useState(false)
    const [ fullDay, setFullDay ] = useState("")
    const [ fullHour, setFullHour ] = useState("")
    const postText = useRichRendered(post, "texto", false)

    const date = useMemo(() =>
        post && post._createdAt && Date.parse(post._createdAt)
    , [post])

    useEffect(() => {
        let fetchPost = async () => {
            try {
                const postAux = await fetch(`${process.env.REACT_APP_API_URL}/blog/posts/${slug}`).then(r => r.json())
                setPost(postAux)
                
                setFullDay(new Intl.DateTimeFormat('es-AR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }).format(date))
                setFullHour(new Intl.DateTimeFormat('es-AR', {
                    minute: '2-digit',
                    hour: '2-digit'
                }).format(date))
                setLoading(false)
            } catch(e) {
                setLoading(false);
                console.log(e);
                setError(true);
            }}
        fetchPost()
    }, [slug, date])

    return (
        <>
            {loading && <div className="w-full flex flex-col items-center mt-10"><BeatLoader color="#FF7272" loading={loading} size={20}></BeatLoader></div>}
            {post &&
                <>
                    <div className={`bg-grey w-full mt-3 md:mt-0 md:min-h-content ${className}`}>
                        <p className="font-ar p-3 pb-0.5 text-base text-black hover:text-dark-pink"><Link href={`/blog/${currentBlogPage}`}>← Volver</Link></p>
                        <p className="font-ar p-3 text-lg">{date && <span>Publicado el {fullDay} a las {fullHour} hs.</span>}{!date && <span>&nbsp;</span>}</p>
                        <h2 className="font-msb p-5 pl-3 pt-1 text-3xl text-center">{post.titulo}</h2>
                        {post.imagen && <img className="md:w-1/4 m-auto mb-10" src={post.imagen.fields.file.url} alt={post.imagen.fields.title}></img>}
                        <div className="md:pl-20 md:pr-20 pb-5 font-ar">
                            {postText}
                        </div>
                        <p className="font-ar p-3 pb-0.5 text-base text-black hover:text-dark-pink"><Link href={`/blog/${currentBlogPage}`}>← Volver</Link></p>
                    </div>
                </>
            }
            {error && <p className="font-ar text-lg text-black p-10">Ocurri&oacute; un error mostrando esta p&aacute;gina</p>}
        </>
    )
}