import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import CustomLink from "../components/CustomLink";
import useStore from "../store/Store";

export default function HeaderNav({className}) {
    const el = document.getElementById("hover-menu")
    const menuOpen = useStore(state => state.menuOpen)
    const isNotMobile = useMediaQuery({ query: `(min-width: 768px)` });

    const toRender = (
        <nav className={`${menuOpen ? "opacity-100 visible z-20" : "opacity-0 invisible z-0"} md:visible md:opacity-100 md:block font-ar
            text-black text-base flex
            ${isNotMobile ? "" : "bg-dark-pink h-full w-full justify-around pt-28 pb-28 transition-all duration-500"}
            flex-col md:flex-row ${className}`}>
            <CustomLink href="/">Inicio</CustomLink>
            <CustomLink href="/acerca-de-mi">Acerca de m&iacute;</CustomLink>
            <CustomLink href="/clases-y-cursos">Clases y Cursos</CustomLink>
            <CustomLink href="/salaVirtual">Sala Virtual</CustomLink>
            <CustomLink href="/formacion">Formaci&oacute;n</CustomLink>
            <CustomLink href="/retiros">Retiros</CustomLink>
            <CustomLink href="/blog/1">Blog</CustomLink>
            <CustomLink href="/contacto">Contacto</CustomLink>
        </nav>)

    return (
        isNotMobile ? 
        (toRender) : (ReactDOM.createPortal(toRender, el))
    )
}