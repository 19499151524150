import { useEffect, useState } from "react"
import { BeatLoader } from "react-spinners"
import Stripe from "../components/Stripe"
import VirtualCoursePreview from "../components/VirtualCoursePreview"
import useStore from "../store/Store"

export default function VirtualRoom() {
    const [virtualCourses, setVirtualCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const loggedUser = useStore(state => state.loggedUser)

    useEffect(() => {
        async function fetchVirtualCourses() {
            const jwt = localStorage.getItem("jwt")
            const headers = {}
            if (jwt) {
                headers.Authorization = 'Bearer ' + jwt
            }
            const result = await fetch(`${process.env.REACT_APP_API_URL}/virtualRoom/listVirtualCourses`, {
                headers
            }).then(r => r.json())
            setLoading(false)

            setVirtualCourses(result.virtualCourses)
        }
        fetchVirtualCourses();
    }, [loggedUser])

    return (
        <>
            <Stripe title="Sala Virtual"></Stripe>
            <div className={`pl-2 pr-2 md:pl-44 md:pr-44 pt-4 md:pt-10 pb-10 flex flex-col 
                flex-wrap md:flex-row items-center md:items-baseline justify-center`}>
                {loading ? <BeatLoader color="#FF7272" loading={loading} size={20}></BeatLoader> :
                (virtualCourses && virtualCourses.length > 0) ? 
                    virtualCourses.map(vc => <VirtualCoursePreview key={vc.id} virtualCourse={vc}></VirtualCoursePreview>)
                :
                    loggedUser ? 
                        <div className="font-ar text-xl mt-4 ml-2 md:ml-5 text-black">No ten&eacute;s ning&uacute;n curso comprado.</div>
                    :
                        <div className="font-ar text-xl mt-4 ml-2 md:ml-5 text-black">&iexcl;Todav&iacute;a no hay cursos disponibles!</div>
                } 
            </div>
        </>
    )
}