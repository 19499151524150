import { useEffect, useMemo } from "react"
import { Link, useLocation } from "wouter"
import Stripe from '../components/Stripe'
import collage from '../images/collage1.webp'
import inicio from '../images/inicio.jpg'
import logo from '../images/logo.png'
import ursu from '../images/ursu.jpg'
import useStore from '../store/Store'

export default function Home() {
    const [location, navigate] = useLocation();
    const toggleLoginModal = useStore(state => state.toggleLoginModal)
    const toggleRegisterModal = useStore(state => state.toggleRegisterModal)
    const loggedUser = useStore(state => state.loggedUser)

    const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location]) 

    useEffect(() => {
        if (urlSearchParams.get("open") === 'login' && !loggedUser) {
            toggleLoginModal()
        } else if (urlSearchParams.get("open") === 'register' && !loggedUser) {
            toggleRegisterModal()
        }
    }, [location, toggleLoginModal, toggleRegisterModal, navigate, loggedUser, urlSearchParams])
    
    return (
        <>
            <Stripe>
                <div className="flex flex-col items-center">
                    <div className="h-72 bg-grey w-11/12 md:w-80 bg-opacity-70 mt-4 mb-4 md:mt-0 md:mb-0 md:ml-96 relative">
                        <p className="font-msb text-3xl mt-8 ml-5">YOGA</p>
                        <p className="font-msb text-3xl ml-5">PARA VIVIR</p>
                        <p className="font-msb text-3xl ml-5">LA VIDA</p>
                        <p className="font-ab text-2xl absolute text-white bg-dark-pink pl-8 pr-8 
                            pt-3 pb-2 hover:text-light-pink bottom-2 w-56 m-auto left-0 right-0">
                            <Link href="/clases-y-cursos">EMPEZ&Aacute; HOY</Link></p>
                    </div>
                </div>
            </Stripe>
            <div className="pl-2 pr-2 md:pl-64 md:pr-64 pt-4 md:pt-10 pb-10 flex flex-col items-center">
                <div className="flex flex-col md:flex-row">
                    <img className="md:max-w-sm" src={ursu} alt="Úrsula haciendo yoga"></img>
                    <div className="w-full bg-grey p-10">
                        <img className="w-20" src={logo} alt="Logo"></img>
                        <p className="text-black font-msb text-2xl mt-5">Acerca de m&iacute;</p>
                        <p className="text-black font-ar text-lg mt-5">¡Hola! Mi nombre es &uacute;rsula y soy profesora de Yoga Integral Sistema A.B.Y.</p>
                        <Link className="text-black font-msb relative top-5 hover:text-dark-pink" to="/acerca-de-mi">Leer m&aacute;s...</Link>
                    </div>
                </div>
                <img src={collage} className="w-full md:max-w-xl" alt="Collage"></img>
                <div className="w-full bg-grey p-10">
                        <p className="text-black font-msb text-2xl mt-5">Clases, cursos, talleres y formaci&oacute;n profesional</p>
                        <p className="text-black font-ar text-lg mt-5">Busc&aacute; la opci&oacute;n m&aacute;s adecuada para vos.</p>
                        <p className="text-black font-ar text-lg mt-5">Te ofrezco clases presenciales y tambi&eacute;n online, para que puedas sumarte desde cualquier parte del mundo.</p>
                        <p className="font-ab text-2xl text-white bg-dark-pink pl-8 pr-8 
                            pt-2 pb-2 hover:text-light-pink bottom-2 w-56 m-auto left-0 right-0 mt-8">
                            <Link href="/clases-y-cursos">EMPEZ&Aacute; HOY</Link>
                        </p>
                </div>
                <img src={inicio} className="w-full md:max-w-4xl" alt="Personas haciendo Yoga"></img>
            </div>
        </>
    )
}