import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import Stripe from '../components/Stripe'
import quieroMiReserva from '../images/quieroreservar.png'
import retsec1 from '../images/retiro-seclantas/retiro_seclantas1.jpg'
import retsec2 from '../images/retiro-seclantas/retiro_seclantas2.jpg'
import retsec3 from '../images/retiro-seclantas/retiro_seclantas3.jpg'
import retsec4 from '../images/retiro-seclantas/retiro_seclantas4.jpg'
import retsec5 from '../images/retiro-seclantas/retiro_seclantas5.jpg'
import retsec6 from '../images/retiro-seclantas/retiro_seclantas6.jpg'
import retiroImg from '../images/retiro.png'
import ret1 from '../images/retiro/ret1.jpeg'
import ret2 from '../images/retiro/ret2.jpeg'
import ret3 from '../images/retiro/ret3.jpeg'
import ret4 from '../images/retiro/ret4.jpeg'
import ret2022 from '../images/retiro/retiro-2022.png'
import retsanta1 from '../images/retirosanta/1.png'
import retsanta11 from '../images/retirosanta/ret_sta_1.jpeg'
import retsanta12 from '../images/retirosanta/ret_sta_2.jpeg'
import retsanta13 from '../images/retirosanta/ret_sta_3.jpeg'
import retsanta14 from '../images/retirosanta/ret_sta_4.jpeg'
import retsanta15 from '../images/retirosanta/ret_sta_5.jpeg'
import retsanta16 from '../images/retirosanta/ret_sta_6.jpeg'

export default function Retiro() {
	const [modalIsOpen, setIsOpen] = useState(false);

	const style = {
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  boxShadow: 24,
	  p: 2,
	  backgroundColor: 'white'
	};

    /*
	function openModal(e) {
		e.preventDefault();
		setIsOpen(true);
	}*/

	function closeModal() {
		setIsOpen(false);
	}

    return (
        <>
            <Stripe title="Retiros">
            </Stripe>
            <div className="bg-grey w-full md:min-h-content pt-4 pb-10">
                <div className="flex flex-col md:flex-row md:flex-wrap md:justify-around md:mt-5 md:gap-8 mb-4">
                    <div className="md:w-thirdScreen hidden md:block">&nbsp;</div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta1}></img></div>
                    <div className="md:w-thirdScreen hidden md:block">&nbsp;</div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta11}></img></div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta12}></img></div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta13}></img></div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta14}></img></div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta15}></img></div>
                    <div className="md:w-thirdScreen"><img className="ml-auto mr-auto md:w-thirdScreen" alt="foto retiro" src={retsanta16}></img></div>
                </div>
                <hr></hr>
                <h2 className="pt-10 pb-10 text-2xl text-center font-ab">RETIRO JUNIO 2022</h2>
                <img className="ml-auto mr-auto md:w-1/3" alt="imagen retiro" src={ret2022}></img>
                <div className="flex flex-col md:flex-row md:flex-wrap md:justify-around md:mt-5">
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto retiro" src={retsec1}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen" alt="foto retiro" src={retsec2}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen md:mt-10 md:mb-4" alt="foto retiro" src={retsec3}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen md:mt-10 md:mb-4" alt="foto retiro" src={retsec4}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen md:mt-10 md:mb-4" alt="foto retiro" src={retsec5}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen md:mt-10 md:mb-4" alt="foto retiro" src={retsec6}></img></div>
                </div>
            </div>
            <hr></hr>
            <h2 className="pt-10 pb-10 text-2xl text-center font-ab">RETIRO OCTUBRE 2021</h2>
            <div className={`bg-grey w-full md:min-h-content pt-4`}>
                <img className="ml-auto mr-auto md:w-1/3" alt="imagen retiro" src={retiroImg}></img>
                <div className="flex flex-col md:flex-row md:flex-wrap md:justify-around md:mt-5">
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto" alt="foto retiro" src={ret1}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen" alt="foto retiro" src={ret2}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen md:mt-10 md:mb-4" alt="foto retiro" src={ret3}></img></div>
                    <div className="md:w-halfScreen"><img className="ml-auto mr-auto md:w-halfScreen md:mt-10 md:mb-4" alt="foto retiro" src={ret4}></img></div>
                </div>
                <h2 className="pt-5 text-3xl text-center font-ab md:text-left md:ml-5">Testimonios</h2>
                <h2 className="pt-5 ml-2 text-xl text-left font-ab md:ml-5">Gabriela</h2>
                <p className="ml-2 text-lg font-ar md:ml-5">Una experiencia &uacute;nica la que viv&iacute; en estos d&iacute;as, d&oacute;nde aprend&iacute; a compartir con personas que no conoc&iacute;a, escuchar sus experiencias de vida fue muy enriquecedor.<br/>
                    Aprend&iacute; a vencer las ideas limitantes, que me permitieron llegar a lugares so&ntilde;ados, d&oacute;nde el contacto con la naturaleza fue &uacute;nico e irrepetible.<br/>
                    Aprend&iacute; a calmar mi mente en la vor&aacute;gine diaria que nos lleva la ciudad, y encontrar en la respiraci&oacute;n que acompa&ntilde;a a la meditaci&oacute;n una paz indescriptible.<br/>
                    Sin dejar de nombrar a Ursu y Fran, dos personas incre&iacute;bles que sin conocerlas dieron todo para que todo fuera perfecto, haciendo de nuestros d&iacute;as el mejor recuerdo en mucho tiempo.<br/>
                    Gracias a todos.
                </p>
                <h2 className="pt-5 ml-2 text-xl text-left font-ab md:ml-5">Maca</h2>
                <p className="ml-2 text-lg font-ar md:ml-5">La bajada tiene que ver con la entrega, le coment&eacute; a una compa&ntilde;era.<br/>Y s&iacute;. La bajada me cost&oacute; un poco m&aacute;s que la subida... subida que seg&uacute;n entiendo yo, tiene m&aacute;s que ver en la parte activa de dar. La parte en que una acciona para dar/darse.<br/>Percib&iacute; enseguida de comenzar a bajar que algo me entristec&iacute;a de dejar atr&aacute;s la cumbre del cerro... y mientras intentaba coordinar el palo de trekking con una pisada segura, algo en m&iacute; ped&iacute;a silencio y contemplaci&oacute;n. Ah&iacute; fu&eacute; cuando comenc&eacute; a sentirme no tan disponible para todo el camino que faltaba.<br/>Si lo pienso un poco, subir el Cerro Elefante es un poco como una met&aacute;fora obvia de la vida... sabiendo que nadie se queda sola, que lo que sea necesario se pide claramente buscando apoyo si lo preciso, al mismo tiempo que la responsabilidad por mi bienestar depende tambi&eacute;n (y en gran medida) de mis elecciones... si llevo el agua suficiente, si dorm&iacute; bien la noche anterior, si soy capaz de estar haciendo mi camino a la vez que me dejo ayudar y acompa&ntilde;ar por otrxs.<br/>Si soy capaz de disfrutar y de compartirme.<br/>La experiencia del Cerro Elefante, como el retiro, es una experiencia de pr&aacute;ctica intensa y expansiva, que estoy segura te&ntilde;ir&aacute; los d&iacute;as por venir.</p>                
            </div>
			<Modal
				open={modalIsOpen}
				onClose={closeModal}>
					<Box sx={style}>
						<img className="mt-2 mb-2 ml-auto mr-auto max-h-af max-w-af" alt="imagen quiero mi reserva" src={quieroMiReserva}></img>
					</Box>
			</Modal>
        </>
    )
}
