import abyBlack from '../images/aby_black.png'
import abyWhite from '../images/aby_white.png'
import fbBlack from '../images/facebook_black.png'
import fbWhite from '../images/facebook_white.png'
import igBlack from '../images/instagram_black.png'
import igWhite from '../images/instagram_white.png'
import ytBlack from '../images/youtube_black.png'
import ytWhite from '../images/youtube_white.png'

export default function Social({ theme, className }) {
    return (
        <div className={className}>
            <a className="float-left" href="https://www.facebook.com/abonaerenseyoga" target="_blank" rel="noopener noreferrer"><img src={theme === 'black' ? abyBlack : abyWhite} width="32" title="ABY" alt="ABY" /></a>
            <a className="float-left ml-2" href="https://www.facebook.com/yogaconursula" target="_blank" rel="noopener noreferrer"><img src={theme === 'black' ? fbBlack : fbWhite} width="32" title="Facebook" alt="Facebook"/></a>
            <a className="float-left ml-2" href="https://instagram.com/yogaconursula" target="_blank" rel="noopener noreferrer"><img src={theme === 'black' ? igBlack : igWhite} width="32" title="Instagram" alt="Instagram" /></a>
            <a className="float-left ml-2" href="https://www.youtube.com/c/Yogacon%C3%9Arsula" target="_blank" rel="noopener noreferrer"><img src={theme === 'black' ? ytBlack : ytWhite} width="32" title="Youtube" alt="Youtube" /></a>
        </div>
    )
}