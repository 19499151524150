import logo from '../images/logo.png'
import Hamburguer from '../components/Hamburger'

export default function HeaderLogo({className}) {
    return (
        <div className={`relative h-40 bg-dark-pink p-5 flex flex-col w-full md:w-80 ${className}`}>
            <Hamburguer/>
            <img className="w-20 m-auto" src={logo} alt='Yoga Integral'></img>
            <h3 className="font-msb text-2xl text-white text-center">&Uacute;rsula R&iacute;os Malan</h3>
        </div>
    )
}