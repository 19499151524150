import Stripe from '../components/Stripe'
import ursumeditamasgrande from '../images/ursumeditamasgrande.webp'

export default function About() {
    return (
        <>
            <Stripe title="Acerca de m&iacute;"></Stripe>
            <div className="pl-2 pr-2 md:pl-20 md:pr-20 pt-4 pb-4 flex flex-col md:flex-row justify-center">
                <div>
                    <img className="m-0 p-0" src={ursumeditamasgrande} alt="Imagen de &Uacute;rsula meditando"></img>
                </div>
                <div className="w-full md:w-2/6 p-5 bg-grey">
                    <h4 className="font-msb text-xl">Acerca de m&iacute;</h4>
                    <p className="font-ar text-lg pt-5">&iexcl;Hola! Mi nombre es &Uacute;rsula, soy Profesora Formadora de la Asociaci&oacute;n Bonaerense de Yoga.</p>
                    <p className="font-ar text-lg pt-5">Como muchas personas, llegu&eacute; al yoga en medio de una crisis: durante muchos a&ntilde;os estudi&eacute; teatro, mi sue&ntilde;o de chica era ser actriz, pero lleg&oacute; un momento en el que eso que me apasionaba, defin&iacute;a y guiaba mi vida; empez&oacute; a generarme mucha angustia, sin siquiera entender del todo por qu&eacute;. Solo pod&iacute;a ver como aquello por lo que tanto me hab&iacute;a esforzado por m&aacute;s de diez a&ntilde;os, se desmoronaba.</p>
                    <p className="font-ar text-lg pt-0">En medio de esa confusi&oacute;n y profunda tristeza, una compa&ntilde;era de danzas me cont&oacute; que era profesora de yoga y me sugiri&oacute; probar.</p>
                    <p className="font-ar text-lg pt-0">Empec&eacute; el instructorado de una, sin pensarlo, me tir&eacute; de cabeza. Y ese lugar en donde cre&iacute;a que encontrar&iacute;a quiz&aacute;s algo de calma, me sorprendi&oacute; con mucho m&aacute;s: un espacio de desarrollo personal y grupal, donde pude comprender cosas sin tener que enredarme en mis pensamientos, donde pude mirarme desde otra perspectiva y disfrutar.</p>
                    <p className="font-ar text-lg pt-0">Fue tan positivo el cambio que provoc&oacute; el yoga en mi vida, que deseo compartirlo con todo el mundo. Esa es mi mayor motivaci&oacute;n a la hora de dar clases, transmitir esa experiencia, sabiendo que se puede vivir m&aacute;s livianamente, que la pr&aacute;ctica compartida multiplica los beneficios y nos potencia.</p>
                    <p className="font-ar text-lg pt-5">Como te contaba, estudi&eacute; teatro, danza contempor&aacute;nea, euton&iacute;a, y todo eso ti&ntilde;e mis clases.</p>
                    <p className="font-ar text-lg pt-0">Por mi experiencia personal puedo asegurarte que el yoga es una hermosa manera de vivir la vida.</p>
                    <p className="font-ar text-lg pt-0">Me encantar&iacute;a compartirla con vos.</p>
                    <p className="font-ar text-lg pt-5">&iexcl;Nos vemos en la pr&aacute;ctica!</p>
                    <ul className="font-ar text-lg pt-5 list-disc">
                        <li>2012 - Egresada de la ETLP</li>
                        <li>2015 - Instructora de Yoga Integral A.B.Y</li>
                        <li>2016 - Profesora de Yoga Integral A.B.Y</li>
                        <li>2017 - Reiki Usui nivel 1</li>
                        <li>2018 - Docente del Método Frida Kaplan Embarazo y Nacimiento Eutónico.</li>
                        <li>2019
                            <ul className="list-inside list-disc">
                                <li>Profesora Formadora de Yoga Integral A.B.Y</li>
                                <li>Reiki Usui nivel 2</li>
                            </ul>
                        </li>
                        <li>2021 - Curso de iniciación al montañismo CAM Salta.</li>
                        <li>2022 - Curso de Primeros Auxilios nivel 1 Servicio Médico de Aconcagua.</li>
                        <li>2023
                            <ul className="list-inside list-disc">
                                <li>Capacitación en el entrenamiento de la movilidad</li>
                                <li>Iniciación al uso de cuencos tibetanos</li>
                                <li>Instructora de Yoga Artístico A.B.Y</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}