import { useEffect, useRef, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { useLocation } from "wouter";
import useStore from "../store/Store";

export default function Validar({params: {token}}) {
    const [validating, setValidating] = useState(true)
    const [error, setError] = useState(null)
    const [valid, setValid] = useState(false)
    const toggleLoginModal = useStore(state => state.toggleLoginModal)
    const setRp = useStore(state => state.setLoggingReturnPath)
    const loggedUser = useStore(state => state.loggedUser)
    const [, navigate] = useLocation();
    const urlSearchParams = useRef(new URLSearchParams(window.location.search))

    useEffect(() => {
        if (token && !loggedUser) {
            fetch(`${process.env.REACT_APP_API_URL}/verify/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(r => r.json()).then(r => {
                if (r.ok) {
                    setValid(true)
                } else {
                    setError(r.message)
                }
                setValidating(false)
            }).catch((e) => {
                console.log(e)
                setError("Ocurrió un error al intentar validar tu mail. Probá nuevamente.")
                setValidating(false)
            })
        } else {
            navigate("/")
        }
    }, [token, navigate, loggedUser])

    return (
        <>
            <div className="flex flex-col items-center pl-5 pr-5 pt-5">
                {validating && 
                    <>
                        <p className="font-ab text-dark-pink text-lg pl-5 pr-5 text-center mb-7">Estamos validando tu mail...</p>
                        <BeatLoader color="#FF7272" loading={validating} size={20}></BeatLoader>
                    </>
                }
                {valid && 
                    <>
                        <p className="font-ab text-dark-pink text-lg pl-5 pr-5 text-center mb-7">&iexcl;Validamos correctamente tu mail!</p>
                        <p className="font-ab text-dark-pink text-lg pl-5 pr-5 text-center mb-7">Ahora pod&eacute;s loguearte con tu mail y contrase&ntilde;a.</p>
                        <button className="font-msb bg-dark-pink text-white hover:text-light-pink pt-2 pb-2 pr-10 pl-10
                        text-center align-middle mt-auto mb-4 focus:outline-none" onClick={() =>{ setRp(urlSearchParams.current.get("returnPath")); toggleLoginModal() } }>Ingresar</button>
                    </>
                }
                {error && <p className="font-abi text-dark-pink text-lg">{error}</p>}
            </div>
        </>
    )
}