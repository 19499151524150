import { Link } from 'wouter'
import Social from '../components/Social'
import useStore from '../store/Store'
import useLogout from '../util/useLogout'
import HeaderLogo from './HeaderLogo'
import HeaderNav from './HeaderNav'

export default function Header() {
    const toggleLoginModal = useStore(state => state.toggleLoginModal)
    const toggleRegisterModal = useStore(state => state.toggleRegisterModal)
    const loggedUser = useStore(state => state.loggedUser)
    const logout = useLogout()

    return (
        <div className="w-full flex flex-col md:flex-row">
            <HeaderLogo className=""/>
            <HeaderNav className="mt-auto"/>
            <div className="ml-auto mr-auto md:mr-5 mt-3 md:mt-5 flex flex-col justify-between items-center md:items-end">
                <Social theme="black"/>
                {!loggedUser &&
                    <div className="font-ar text-black flex flex-row md:mb-4 text-base mt-2 md:mt-0">
                        <>
                            <button onClick={toggleLoginModal} className="hover:text-dark-pink focus:outline-none">Ingresar</button>
                            <p>&nbsp;-&nbsp;</p>
                            <button onClick={toggleRegisterModal} className="hover:text-dark-pink focus:outline-none">Registrarse</button>
                        </>
                    </div>
                }
                {loggedUser &&
                    <div className="font-ar text-black flex flex-col md:mb-4 text-base mt-2 md:mt-0 items-center md:items-end">
                        <>
                            <p>&iexcl;Hola{loggedUser.name ? ' ' + loggedUser.name : ''}!</p>
                            <div className="flex flex-row">
                                <p className="hover:text-dark-pink"><Link href="mis-datos">Mis Datos</Link></p>
                                <p>&nbsp;-&nbsp;</p>
                                <button onClick={logout} className="hover:text-dark-pink focus:outline-none">Salir</button>
                            </div>
                        </>
                    </div> 
                }
            </div>
        </div>
    )
}