import MDEditor from "@uiw/react-md-editor"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { BeatLoader } from "react-spinners"
import { Link, useLocation } from "wouter"
import Stripe from "../components/Stripe"
import wsp from '../images/wsp.png'
import useStore from "../store/Store"

export default function VirtualCourse({params: {idCurso, idClase}}) {
    const jwt = localStorage.getItem("jwt")
    const [virtualCourse, setVirtualCourse] = useState({})
    const [loading, setLoading] = useState(true)
    const loggedUser = useStore(state => state.loggedUser)
    const [classNumber, setClassNumber] = useState(null)
    const [, navigate] = useLocation();
    const [classToShow, setClassToShow] = useState(null)
    const isNotMobile = useMediaQuery({ query: `(min-width: 768px)` });
    const toggleRegisterModal = useStore(state => state.toggleRegisterModal)
    const toggleLoginModal = useStore(state => state.toggleLoginModal)
    const setRp = useStore(state => state.setLoggingReturnPath)
    const [scroll, setScroll] = useState(0)
    const titleRef = useRef(null)

    useEffect(() => {
        async function fetchVirtualCourse() {
            const jwt = localStorage.getItem("jwt")
            const headers = {}
            if (jwt) {
                headers.Authorization = 'Bearer ' + jwt
            }
            const result = await fetch(`${process.env.REACT_APP_API_URL}/virtualRoom/course/${idCurso}`, {
                headers
            }).then(r => r.json())
            setLoading(false)
            setVirtualCourse(result.virtualCourse)
        }
        fetchVirtualCourse()
    }, [loggedUser, idCurso])

    useEffect(() => {
        if (idClase) {
            if (!isNaN(Number(idClase))) {
                setClassNumber(Number(idClase)-1)
                setScroll(s => s + 1)
            } else {
                navigate(`/cursoVirtual/${idCurso}/1`)
            }
        } else {
            setClassNumber(0)
        }
    }, [idClase, idCurso, navigate])

    useEffect(() => {
        if (virtualCourse?.videos && virtualCourse?.videos?.length > 0) {
            if (classNumber >= 0 && classNumber < virtualCourse?.videos.length) {
                setClassToShow(virtualCourse?.videos[classNumber])
            } else {
                navigate(`/cursoVirtual/${idCurso}/1`)
            }
        }
    }, [virtualCourse, classNumber, idCurso, navigate])

    const canSeeClases = useMemo(() => {
        return !virtualCourse?.requiresRegistered || loggedUser;
    }, [virtualCourse, loggedUser])

    const watsWrong = useMemo(() => {
        if (virtualCourse?.requiresRegistered && !virtualCourse?.requiresBuy) {
            return ["Tenés que registrarte para poder ver este curso.", 
                <span>Registrate <button onClick={() => {setRp('/cursoVirtual/'+virtualCourse.slug); toggleRegisterModal()}} className="font-ab text-dark-pink hover:text-black underline focus:outline-none">ac&aacute;.</button></span>,
                <span>Si ya estás registrado iniciá sesión <button onClick={toggleLoginModal} className="font-ab text-dark-pink hover:text-black underline focus:outline-none">ac&aacute;</button>.</span>]
        } else if (virtualCourse?.requiresBuy) {
            return ["Tenés que comprar este curso para poder verlo."]
        } else {
            return ["Pasó algo raro..."]
        }
    }, [virtualCourse, toggleRegisterModal, toggleLoginModal, setRp])

    const goToClass = useCallback((classNumber) => {
        //setClassNumber(classNumber)
        //setScroll(s => s + 1)
        navigate(`/cursoVirtual/${idCurso}/${classNumber+1}`)
    }, [idCurso, navigate])

    useEffect(() => {
        if (scroll && scroll > 0 && titleRef && titleRef.current) {
            titleRef.current.scrollIntoView()
        }
    }, [scroll, titleRef, classNumber])

    useEffect(() => {

    })

    return (
    <>
        <Stripe title="Curso Virtual">
            {virtualCourse?.name ? 
                <>
                    <p className="md:mt-3 text-center font-msb text-white text-lg md:text-3xl ts-1px">{virtualCourse.name}</p>
                </> : <></>}
        </Stripe>
        {loading ? <BeatLoader color="#FF7272" loading={loading} size={20}></BeatLoader> : <>
            {virtualCourse?.name ? 
                <div className="bg-grey w-full md:min-h-content md:pl-20 md:pr-20 pb-5 pt-5">
                    {!canSeeClases ? 
                        <div className="flex flex-col md:flex-row mb-2 md:mb-0">
                            { watsWrong.map((t, idx) => <p key={idx} className="md:mr-4 font-ar text-l mt-4 mx-4 md:mx-0 text-black">{t}</p>)}
                        </div> : null}
                    
                        <div className="flex flex-row items-center justify-center md:mt-4 mb-10">
                            {virtualCourse?.intro ? 
                                <video controls className="w-screen md:w-thirdScreen" src={`${process.env.REACT_APP_API_URL}/video/${virtualCourse?.intro.slug}`}></video>
                            :   <img className="w-screen md:w-thirdScreen" src={`${process.env.REACT_APP_API_URL}/static/images/${virtualCourse.thumbnail}`} alt={virtualCourse.name}></img>}
                        </div>                    
                        {virtualCourse.shortText ? <p className="font-ar p-5 pl-3 pt-1 text-xl text-center">{virtualCourse.shortText}</p> : null }
                        {virtualCourse.shortTexts ? virtualCourse.shortTexts.map((t, idx) => <p key={idx} className="font-ar p-5 pl-3 pt-1 text-xl text-center">{t}</p>) : null } 
                        {virtualCourse.description ? 
                            <>
                                <hr className="text-dark-pink mb-10"/>
                                <div data-color-mode="light" className="mb-10 px-5 md:px-0">
                                    <MDEditor.Markdown className="custom-md" source={virtualCourse.description}></MDEditor.Markdown>
                                </div>
                            </> : null}
                        <hr className="text-dark-pink mb-5 md:mb-10"/>
                        <p className="w-80 font-msb bg-dark-pink text-white hover:text-light-pink ml-3 mt-3 pt-3 pb-3 pr-4 pl-4 text-center align-middle2">
                            <img className="float-left mt-1 mr-1" src={wsp} width="16" alt="logo de whatsapp"></img>
                            <a href="https://wa.me/message/FGLZHXAPC7OTK1" target="_blank" rel="noreferrer">Quiero recibir m&aacute;s informaci&oacute;n</a>
                        </p>
                        {!canSeeClases ? watsWrong.map((t, idx) => <p key={idx} className="font-ar text-xl mt-4 mx-4 md:mx-0 text-black">{t}</p>) : null}
                        {canSeeClases && classToShow ? 
                            <div className="flex flex-col md:flex-row md:justify-between md:mb-10">
                                {!isNotMobile && virtualCourse?.videos?.length > 0 ?
                                    <>
                                        <div className="flex flex-row justify-between mx-3">
                                            {classNumber > 0 ? <Link className="hover:text-dark-pink" href="" onClick={() => {goToClass(classNumber-1)}}>&lt; Clase {classNumber}</Link> : null}
                                            {classNumber+1 < virtualCourse?.videos?.length ? <Link className="hover:text-dark-pink  ml-auto" href="" onClick={() => {goToClass(classNumber+1)}}>Clase {classNumber+2} &gt;</Link> : null}
                                        </div>
                                        <hr className="text-dark-pink mt-5 mb-5"/>
                                    </>: null}
                                <div className="flex flex-col px-5 md:px-0 md:mr-6">
                                    <h3 id={`clase-${classNumber}`} ref={titleRef} className="font-msb text-xl mb-10">{classToShow.title}</h3>
                                    <div data-color-mode="light" className="md:w-thirdScreen2 mb-10 pr-5">
                                        <MDEditor.Markdown className="custom-md" source={classToShow.text}></MDEditor.Markdown>
                                    </div>
                                    {isNotMobile && virtualCourse?.videos?.length > 0 ?
                                        <>  
                                            <hr className="text-dark-pink mb-10"/>
                                            <div className="flex flex-row justify-between">
                                                {classNumber > 0 ? <Link className="hover:text-dark-pink" href="" onClick={() => {goToClass(classNumber-1)}}>Volver a clase {classNumber}</Link> : null}
                                                {classNumber+1 < virtualCourse?.videos?.length ? <Link className="hover:text-dark-pink ml-auto" href="" onClick={() => {goToClass(classNumber+1)}}>Continuar a clase {classNumber+2}</Link> : null}
                                            </div>
                                        </> : null}
                                </div>
                                <video id="clase-actual" controls className="w-screen md:w-thirdScreen" src={`${process.env.REACT_APP_API_URL}/video/${classToShow.slug}${jwt ? `?jwt=${jwt}`:''}`}>
                                    No podes ver este video en tu navegador.
                                </video>
                                {!isNotMobile && virtualCourse?.videos?.length > 0 ?
                                    <div className="flex flex-row justify-between mx-3 mt-5">
                                        {classNumber > 0 ? <Link className="hover:text-dark-pink" href="" onClick={() => {goToClass(classNumber-1)}}>&lt; Clase {classNumber}</Link> : null}
                                        {classNumber+1 < virtualCourse?.videos?.length ? <Link className="hover:text-dark-pink  ml-auto" href="" onClick={() => {goToClass(classNumber+1)}}>Clase {classNumber+2} &gt;</Link> : null}
                                    </div>: null}
                            </div> : null}
                </div> : 
                <p className="font-ar text-xl mt-4 ml-2 md:ml-5 text-black">No ten&eacute;s acceso a este curso.</p>
            }
        </>}
    </>)
}