import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { useLocation } from 'wouter';
import useStore from '../store/Store';

export default function LoginModal({location}) {
    const emailInput = useRef(null)
    const pwdInput = useRef(null)
    const [logging, setLogging] = useState(false)
    const opacityCloack = useRef(null);
    const showLoginModal = useStore(state => state.showLoginModal)
    const toggleLoginModal = useStore(state => state.toggleLoginModal)
    const toggleForgotPasswordModal = useStore(state => state.toggleForgotPasswordModal)
    const setLoggedUser = useStore(state => state.setLoggedUser)
    const rp = useStore(state => state.loggingReturnPath)
    const [, navigate] = useLocation();
    
    const [error, setError] = useState(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [pshow, setPshow] = useState(false)

    const urlSearchParams = useRef(new URLSearchParams(window.location.search))
    
    useEffect(() => {
        if (showLoginModal)
            setError(null)
            setLogging(false)
            setPassword("")
            if (urlSearchParams.current.get("email")) {
                setEmail(urlSearchParams.current.get("email"))
                pwdInput && pwdInput.current && pwdInput.current.focus()
            } else {
                setEmail("")
                emailInput && emailInput.current && emailInput.current.focus()
            }
    }, [showLoginModal])

    const login = useCallback(() => {
        setLogging(true)
        fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email?.trim(),
                password: password
            })
        }).then(r => {
            if (r.headers.get("Authorization")) {
                localStorage.setItem("jwt", r.headers.get("Authorization"))
            }
            return r.json()
        }).then(r => {
            if (r.ok) {
                setLoggedUser(r.user)
                toggleLoginModal()
                if (rp) {
                    navigate(rp)
                }
            } else {
                setError(r.message)
            }
            setLogging(false)
        }).catch((e) => {
            console.log(e)
            setError("Ocurrió un error al intentar ingresar. Probá nuevamente.")
            setLogging(false)
        })
    }, [email, navigate, password, rp, setLoggedUser, toggleLoginModal])

    const forgotPassword = useCallback(() => {
        toggleLoginModal()
        toggleForgotPasswordModal()
    }, [toggleForgotPasswordModal, toggleLoginModal])
    
    return (
        showLoginModal ? 
        createPortal(
            <>
            <div ref={opacityCloack} onClick={(e) => {
                if (e.target===opacityCloack.current && !logging) {
                    toggleLoginModal()
                }
            }} className={`flex flex-col fixed w-screen h-screen
                bg-black bg-opacity-40 justify-center items-center transition-all duration-300
                ${showLoginModal ? "z-50 opacity-100" : "z-neg-100 opacity-0"}`}
                onKeyPress={e => {
                    if (e.key === "Enter") {
                        login()
                    }
                }}>
                <div className="w-11/12 h-4/6 md:w-1/2 md:h-1/2 bg-white rounded-md shadow-sm flex flex-col items-center">
                    <button onClick={() => {
                        if (!logging) toggleLoginModal()
                    }} className="pt-2 pr-3 ml-auto font-msb focus:outline-none text-dark-pink hover:text-light-pink">X</button>
                    <p className="font-ab text-dark-pink text-lg">Email</p>
                    <input className="font-ar text-black mt-1 pl-5 pr-5 pt-1 pb-1 focus:outline-none border-2
                        rounded-md border-grey focus:shadow-md w-5/6 md:w-2/4"
                        disabled={logging}
                        value={email}
                        placeholder="Ingres&aacute; tu email..."
                        onChange={(e) => setEmail(e.target.value)}
                        ref={emailInput} name="username" id="username"></input>
                    <br></br>
                    <p className="mt-2 font-ab text-dark-pink text-lg">Contraseña</p>
                    <div className='w-5/6 md:w-2/4 relative'>
                        <input className="font-ar w-full text-black mt-1 pl-5 pr-5 pt-1 pb-1 focus:outline-none border-2
                            rounded-md border-grey focus:shadow-md"
                            disabled={logging}
                            value={password}
                            placeholder="Ingres&aacute; tu contraseña..." type={pshow ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                            ref={pwdInput} name="password" id="password">
                        </input>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                            <svg fill="none" onClick={() => setPshow(s => !s)} className={(pshow ? 'hidden' : 'show') + ' h-6 text-gray-700 cursor-pointer'} xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512">
                                <path fill="currentColor"
                                    d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                </path>
                            </svg>

                            <svg fill="none" onClick={() => setPshow(s => !s)} className={(pshow ? 'show' : 'hidden') + ' h-6 text-gray-700 cursor-pointer'} xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512">
                                <path fill="currentColor"
                                    d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <br></br>
                    <p><button onClick={() => forgotPassword()} className="mt-3 font-ab text-dark-pink hover:text-black underline focus:outline-none">&iquest;Olvidaste tu contrase&ntilde;a?</button></p>
                    {error && !logging && <p className="font-abi text-dark-pink text-lg p-3 text-center">{error}</p>}
                    {logging && <BeatLoader color="#FF7272" loading={logging} size={20}></BeatLoader>}
                    <button className="font-msb bg-dark-pink text-white hover:text-light-pink pt-2 pb-2 pr-10 pl-10
                        text-center align-middle mt-auto mb-4 focus:outline-none" onClick={() => login()}>Ingresar</button>
                </div>
            </div>
        </>,  document.getElementById("modal")) : <></>
    )
}