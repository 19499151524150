import useStore from '../store/Store'

export default function Hamburguer() {
    const el = document.getElementById("hover-menu")
    const menuOpen = useStore(state => state.menuOpen)
    const toggleMenu = useStore(state => state.toggleMenu)

    return (
        <div className='p-0 flex flex-col justify-around outline-none 
            top-4 right-4 absolute md:hidden bg-transparent w-5 h-5 z-30 cursor-pointer' onClick={() => {
                el.style["z-index"] = menuOpen ? "-1" : "1"
                toggleMenu()
            }}>
            <div className={`w-full h-1 bg-light-pink transition-all transform origin-1 ${menuOpen ? "rotate-45" : "rotate-0"}`}></div>
            <div className={`w-full h-1 bg-light-pink transition-all ${menuOpen ? "opacity-0" : "opacity-100"}`}></div>
            <div className={`w-full h-1 bg-light-pink transition-all transform origin-1 ${menuOpen ? "-rotate-45" : "rotate-0"}`}></div>
        </div>
    )
}