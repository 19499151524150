import { useEffect, useState } from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { useLocation } from 'wouter'
import Stripe from '../components/Stripe'
import useStore from '../store/Store'
import useLogout from '../util/useLogout'

export default function UserData() {
    const logout = useLogout()
    const loggedUser = useStore(state => state.loggedUser)
    const setLoggedUser = useStore(state => state.setLoggedUser)
    const [, navigate] = useLocation();
    const [savingName, setSavingName] = useState(false)
    const [savingNameError, setSavingNameError] = useState(false)

    const [name, setName] = useState((loggedUser && loggedUser.name) || "")

    const [currentPwd, setCurrentPwd] = useState("")
    const [newPwd, setNewPwd] = useState("")
    const [confirmNewPwd, setConfirmNewPwd] = useState("")
    const [changingPassword, setChangingPassword] = useState(false)
    const [changingError, setChangingError] = useState(false)

    function changePassword() {
        console.log(newPwd, confirmNewPwd, confirmNewPwd === newPwd)
        if (newPwd !== confirmNewPwd) {
            setChangingError("Las contraseñas nuevas no coinciden.")
            return
        }
        if (!currentPwd || currentPwd.trim() === "") {
            setChangingError("No ingresaste tu contraseña actual.")
            return
        }
        if (!newPwd || newPwd.trim() === "") {
            setChangingError("Tu nueva contraseña no puede estar vacía.")
            return
        }

        setChangingError(null)
        setChangingPassword(true)
        fetch(`${process.env.REACT_APP_API_URL}/changePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("jwt")}`
            },
            body: JSON.stringify({password: currentPwd, newPassword: newPwd})
        }).then(r => r.json()).then(r => {
            if (r.logout) {
                logout()
                navigate('/')
            } else {
                setChangingError(r.message)
                if (r.ok) {
                    setCurrentPwd("")
                    setNewPwd("")
                    setConfirmNewPwd("")
                }
            }
        }).catch(e => {
            setSavingNameError("Ocurrió un error cambiando tu contraseña Intentalo nuevamente.")
        }).finally(() => {setChangingPassword(false)})
    }

    function saveName() {
        setSavingName(true)
        setSavingNameError(false)
        fetch(`${process.env.REACT_APP_API_URL}/changeName`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("jwt")}`
            },
            body: JSON.stringify({name: name})
        }).then(r => r.json()).then(r => {
            if (r.ok) {
                loggedUser.name = name
                setLoggedUser(loggedUser)
            } else {
                if (r.logout) {
                    logout()
                    navigate('/')
                } else {
                    setSavingNameError(r.message)
                }
            }
        }).catch(e => {
            setSavingNameError("Ocurrió un error guardando tu nombre. Intentalo nuevamente.")
        }).finally(() => {setSavingName(false)})
    }

    useEffect(() => {
        if (!loggedUser) {
            navigate('/')
        }
    }, [navigate, loggedUser])

    return (
        loggedUser &&
        <>         
            <Stripe title="Mis Datos"></Stripe>
            <div className="m-3 md:m-5 p-3 md:p-5 rounded-md bg-light-pink">
                <p className="text-center font-ab text-lg">Ac&aacute; te mostramos tus datos.</p>
                <br/>
                <p className="font-ar text-black text-lg">Mi email: <span className="font-ab">{loggedUser.email}</span></p>
                <br/>
                <p className="font-ar text-black text-lg">Mi nombre:
                    <input className="ml-2 focus:outline-none font-ab p-1 w-3/5 md:w-96 rounded-sm shadow-sm" type="text" value={name}
                    onChange={(e) => setName(e.target.value)}></input>
                    {name !== (loggedUser.name || "") && !savingName && <button className="ml-2 hover:text-dark-pink focus:outline-none" onClick={saveName}>Guardar</button>}
                    {savingName && <span className="ml-2"><BeatLoader color="#FF7272" loading={savingName} size={10}></BeatLoader></span>}
                    {savingNameError && <span className="ml-2 font-ai text-dark-pink">{savingNameError}</span>}
                </p>
                <br/>
                <br/>
                <p className="font-ab text-black text-lg">Cambiar contrase&ntilde;a</p>
                <p className="font-ar text-black text-lg mt-2">Contraseña actual:
                    <input className="ml-2 focus:outline-none font-ab p-1 w-3/5 md:w-96 rounded-sm shadow-sm" type="password" value={currentPwd}
                    onChange={(e) => setCurrentPwd(e.target.value)}></input>
                </p>
                <p className="font-ar text-black text-lg mt-2">Contraseña nueva:
                    <input className="ml-2 focus:outline-none font-ab p-1 w-3/5 md:w-96 rounded-sm shadow-sm" type="password" value={newPwd}
                    onChange={(e) => setNewPwd(e.target.value)}></input>
                </p>
                <p className="font-ar text-black text-lg mt-2">Confirmar contraseña nueva:
                    <input className="ml-2 focus:outline-none font-ab p-1 w-3/5 md:w-96 rounded-sm shadow-sm" type="password" value={confirmNewPwd}
                    onChange={(e) => setConfirmNewPwd(e.target.value)}></input>
                </p>
                {!changingPassword && <button className="text-black text-lg font-ar mt-2 hover:text-dark-pink focus:outline-none" onClick={changePassword}>Cambiar</button>}
                {changingPassword && <p className="mt-2"><BeatLoader color="#FF7272" loading={changingPassword} size={10}></BeatLoader></p>}
                {changingError && <p className="text-lg mt-2 font-ai text-dark-pink">{changingError}</p>}
            </div>
        </>
    )
}