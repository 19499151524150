import { Link } from "wouter"

export default function VirtualCoursePreview({className, virtualCourse }) {
    return (
        <div className={`bg-grey w-11/12 md:w-32p ml-1 mr-1 mb-2 p-5 flex flex-col items-center ${className}`}>
            {virtualCourse.thumbnail ? <img className="w-40 h-60 object-cover mt-2 mb-2 md:mt-8 md:mb-8 rounded-md" src={`${process.env.REACT_APP_API_URL}/static/images/${virtualCourse.thumbnail}`} alt={virtualCourse.name}></img> : null}
            <p className="font-ar font-base text-2xl text-center mb-5">{virtualCourse.name}</p>
            {virtualCourse.shortText ? <p className="font-ar font-base text-black text-center mb-10 md:text-sm">{virtualCourse.shortText}</p> : null }
            {virtualCourse.shortTexts ? virtualCourse.shortTexts.map(t => <p key={t} className="font-ar font-base text-black text-center mb-10 md:text-sm">{t}</p>) : null } 
            <p className="font-msb bg-dark-pink text-white hover:text-light-pink pt-3 pb-3 pr-10 pl-10 text-center align-middle">
                <Link href={`/cursoVirtual/${virtualCourse.slug}`}>Ver M&aacute;s</Link>
            </p>
        </div>
    )
}