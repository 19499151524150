import create from 'zustand';

const useStore = create(set => ({
    // menu open
    menuOpen: false,
    toggleMenu: () => set(state => ({menuOpen: !state.menuOpen})),

    // current blog page
    currentBlogPage: 1,
    setCurrentBlogPage: page => set(() => ({currentBlogPage: page})),

    // login stuff
    loggedUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    showLoginModal: false,
    showRegisterModal: false,
    showForgotPasswordModal: false,
    setLoggedUser: user => {
        if (user) {
            set(() => ({loggedUser: {...user}}));
            localStorage.setItem("user", JSON.stringify(user))
        } else {
            set(() => ({loggedUser: null}));
            localStorage.removeItem("user")
        }
    },
    loggingReturnPath: '',
    toggleLoginModal: () => set(state => ({showLoginModal: !state.showLoginModal})),
    toggleRegisterModal: () => set(state => ({showRegisterModal: !state.showRegisterModal})),
    toggleForgotPasswordModal: () => set(state => ({showForgotPasswordModal: !state.showForgotPasswordModal})),
    setLoggingReturnPath: rp => set(() => { return ({loggingReturnPath: rp})})

}));

export default useStore