import { useEffect, useState } from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { Link } from "wouter"
import Stripe from '../components/Stripe'
import wsp from '../images/wsp.png'
import useRichRendered from '../util/useRichRendered'

export default function Course({className, params: {slug}}) {
    const [ loading, setLoading ] = useState(true)
    const [ course, setCourse ] = useState(null)
    const [ error, setError ] = useState(false)
    const courseText = useRichRendered(course, "descripcionLarga", false)

    useEffect(() => {
        let fetchPost = async () => {
            try {
                const postAux = await fetch(`${process.env.REACT_APP_API_URL}/course/${slug}`).then(r => r.json())
                setCourse(postAux)
                setLoading(false)
            } catch(e) {
                setLoading(false);
                console.log(e);
                setError(true);
            }}
        fetchPost()
    }, [slug])

    return (
        <>
            <Stripe title="Cursos y Talleres"></Stripe>
            {loading && <div className="w-full flex flex-col items-center mt-10"><BeatLoader color="#FF7272" loading={loading} size={20}></BeatLoader></div>}
            {course &&
                <>
                    <div className={`bg-grey w-full md:min-h-content ${className}`}>
                        <p className="font-ar p-3 pb-0.5 text-base text-black hover:text-dark-pink"><Link href={"/clases-y-cursos"}>← Volver</Link></p>
                        <h2 className="font-msb p-5 pl-3 pt-5 text-3xl text-center">{course.nombre}</h2>
                        <h2 className="font-ar p-5 pl-3 pt-1 text-xl text-center">{course.descripcionCorta}</h2>
                        {(course.icono && !course.imagenAdicional) && <img className="m-auto mb-10" src={course.icono.fields.file.url} alt={course.icono.fields.title}></img>}
                        {course.imagenAdicional && <img className="m-auto mb-10" src={course.imagenAdicional.fields.file.url} alt={course.imagenAdicional.fields.title}></img>}
                        {course.trailer && <video className="m-auto" controls controlsList="nodownload"><source  src={course.trailer.fields.file.url} type={course.trailer.fields.file.contentType}></source></video>}
                        <div className="md:pl-20 md:pr-20 pb-5 pt-5 font-ar">
                            {courseText}
                            {course.slug === 'yoga-y-trekking' && 
                                <>
                                    <p className="text-lg p-3">Sumate al grupo de whatsapp para estar al tanto de nuestras salidas:</p>
                                    <p className="mb-8 w-40 font-msb bg-dark-pink text-white hover:text-light-pink ml-3 mt-3 pt-3 pb-3 pr-0 pl-0 text-center align-middle">
                                        <a href="https://chat.whatsapp.com/HV9kLvt8koS1W8QpMz1XV1">Sumate</a>
                                            <img className="float-right relative right-6" src={wsp} width="25" alt="carrito de compras"></img>
                                        </p>
                                </>
                            }
                            <hr className="text-dark-pink"/>
                            <p className="font-ab text-lg pl-3 pt-3">Precio: Consultar</p>
                            <p className="font-ar text-lg pl-3 pt-3">&iexcl;Gracias por ponerte en contacto!</p>
                            <p className="font-ar text-lg pl-3 pt-3">Nos vemos en la pr&aacute;ctica 🙏🕉</p>
                            <p className="font-ar text-lg pl-3 pt-3">&Uacute;rsula</p>
                            <p className="w-80 font-msb bg-dark-pink text-white hover:text-light-pink ml-3 mt-3 pt-3 pb-3 pr-4 pl-4 text-center align-middle2">
                                <img className="float-left mt-1 mr-1" src={wsp} width="16" alt="logo de whatsapp"></img>
                                <a href="https://wa.me/message/FGLZHXAPC7OTK1" target="_blank" rel="noreferrer">Quiero recibir m&aacute;s informaci&oacute;n</a>
                            </p>                             
                        </div>
                        <p className="font-ar p-3 pb-0.5 text-base text-black hover:text-dark-pink"><Link href={"/clases-y-cursos"}>← Volver</Link></p>
                    </div>
                </>
            }
            {error && <p className="font-ar text-lg text-black p-10">Ocurri&oacute; un error mostrando esta p&aacute;gina</p>}
        </>
    )
}