import { Route, Switch } from 'wouter'
import About from '../pages/About'
import Blog from '../pages/Blog'
import BlogPost from '../pages/BlogPost'
import Clazz from '../pages/Clazz'
import Contact from '../pages/Contact'
import Course from '../pages/Course'
import CourseClases from '../pages/CourseClases'
import Courses from '../pages/Courses'
import Formation from '../pages/Formation'
import Home from '../pages/Home'
import MercadoPagoResult from '../pages/MercadoPagoResult'
import NotFound from '../pages/NotFound'
import Recuperar from '../pages/Recuperar'
import Retiro from '../pages/Retiro'
import Store from '../pages/Store'
import StoreGiftCard from '../pages/StoreGitfCard'
import StoreTShirts from '../pages/StoreTshirts'
import UserData from '../pages/UserData'
import Validar from '../pages/Validar'
import VirtualCourse from '../pages/VirtualCourse'
import VirtualRoom from '../pages/VirtualRoom'

export default function Content() {
    return (
        <div className="min-h-content z-10">
            <Switch>
                <Route path="/" component={Home} />
                <Route path="/acerca-de-mi" component={About} />
                <Route path="/clases-y-cursos" component={Courses} />
                <Route path="/clases/:slug/:order" component={Clazz} />
                <Route path="/cursos/:slug" component={Course} />
                <Route path="/blog/:page" component={Blog} />
                <Route path="/blog/post/:slug" component={BlogPost} />
                <Route path="/contacto" component={Contact} />
                <Route path="/validar/:token" component={Validar} />
                <Route path="/recuperar/:token" component={Recuperar} />
                <Route path="/mis-datos" component={UserData} />
                <Route path="/clases/:slug" component={CourseClases} />
                <Route path="/pago/:status" component={MercadoPagoResult} />
                <Route path="/retiros" component={Retiro} />
                <Route path="/tienda" component={Store} />
                <Route path="/tienda/remeras" component={StoreTShirts} />
                <Route path="/tienda/giftcard" component={StoreGiftCard} />
                <Route path="/formacion" component={Formation} />
                <Route path="/salaVirtual" component={VirtualRoom} />
                <Route path="/cursoVirtual/:idCurso/:idClase?" component={VirtualCourse} />
                <Route><NotFound></NotFound></Route>
            </Switch>
        </div>
    )
}
