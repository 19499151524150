import { useEffect, useState } from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { Link } from 'wouter'
import BlogPostPreview from '../components/BlogPostPreview'
import Stripe from '../components/Stripe'
import useStore from "../store/Store"

export default function Blog({params: {page}}) {
    const setCurrentBlogPage = useStore(state => state.setCurrentBlogPage)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [posts, setPosts] = useState([])
    const [showNextPage, setShowNextPage] = useState(false)

    setCurrentBlogPage(page)

    useEffect(() => {
        let fetchPosts = async () => {
            try {
                const result = await fetch(`${process.env.REACT_APP_API_URL}/blog/posts?page=${page}`).then(r => r.json())
                
                if (result.items) {
                    setShowNextPage((((page-1) * result.size) + result.items.length) < result.total)
                    setPosts(result.items)
                }
                
                setLoading(false)
            } catch(e) {
                setLoading(false);
                console.log(e);
                setError(true);
            }
        }
        fetchPosts()
    }, [page])

    return (
        <>
            <Stripe title="Blog"></Stripe>
            <div className={`pl-2 pr-2 md:pl-28 md:pr-28 pt-4 md:pt-10 pb-10 flex flex-col 
                flex-wrap md:flex-row items-center md:items-baseline ${loading ? 'justify-center' : 'justify-start'}`}>
                {loading && <BeatLoader color="#FF7272" loading={loading} size={20}></BeatLoader>}
                {!loading && !error && posts && posts.length === 0 && <p className="font-ar text-lg text-black">No se encontraron posts.</p>}
                {posts && posts.map(post => 
                    <BlogPostPreview key={post._id} post={post}></BlogPostPreview> 
                )}
                {error && <p className="font-ar text-lg text-black">Ocurri&oacute; un error mostrando esta p&aacute;gina.</p>}
            </div>
            {!error && 
                <div className="pl-2 pr-2 md:pl-28 md:pr-28 pt-4 md:pt-10 pb-10 flex flex-row">
                    {(Number(page) > 1) && <Link className="hover:text-dark-pink mr-auto" to={`/blog/${Number(page) - 1}`}>Ver m&aacute;s nuevos</Link>}
                    {showNextPage && <Link className="hover:text-dark-pink ml-auto" to={`/blog/${Number(page) + 1}`}>Ver m&aacute;s viejos</Link>}
                </div>
            }
        </>
    )
}