import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { BeatLoader } from "react-spinners";
import useStore from "../store/Store";

export default function ForgotPasswordModal() {
    const emailInput = useRef(null)
    const opacityCloack = useRef(null);
    const showForgotPasswordModal = useStore(state => state.showForgotPasswordModal)
    const toggleForgotPasswordModal = useStore(state => state.toggleForgotPasswordModal)
    const recaptcha = useRef(null)

    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [email, setEmail] = useState("")
    const [recaptchaValue, setRecaptchaValue] = useState(null)

    function captchaChanged(value) {
        setRecaptchaValue(value)
    }

    useEffect(() => {
        if (showForgotPasswordModal) {
            setError(null)
            setSending(false)
            setRecaptchaValue(null)
            recaptcha.current && recaptcha.current.reset()
        }
    }, [showForgotPasswordModal])

    const send = useCallback(() => {
        if (email && email.trim() !== '') {
            setSending(true);
            fetch(`${process.env.REACT_APP_API_URL}/forgotPw`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email?.trim(),
                    recaptcha: recaptchaValue
                })
            }).then(r => r.json()).then(r => {
                if (r.ok) {
                    setError("¡Listo! Si existe el mail ingresado te llegará un correo para recuperar tu cuenta.")
                } else {
                    setError(r.message)
                }
                setSending(false)
            }).catch((e) => {
                console.log(e)
                setError("Ocurrió un error al intentar recuperar la contraseña. Probá nuevamente.")
                setSending(false)
            }).finally(() => {
                recaptcha.current && recaptcha.current.reset()
                setRecaptchaValue(null)
            })
        } else {
            setError("Tenés que ingresar un mail.")
        }
    }, [setSending, email, recaptchaValue])

    return (showForgotPasswordModal ? createPortal(<>
        <div ref={opacityCloack} onClick={(e) => {
                if (e.target===opacityCloack.current && !sending) {
                    toggleForgotPasswordModal()
                }
            }} className={`flex flex-col fixed w-screen h-screen bg-black bg-opacity-40 justify-center items-center transition-all duration-300
            ${showForgotPasswordModal ? "z-50 opacity-100" : "z-neg-100 opacity-0"}`}>
            <div className="w-11/12 h-4/6 md:w-1/2 md:h-1/2 bg-white rounded-md shadow-sm flex flex-col items-center">
                <button onClick={() => {
                    if (!sending) toggleForgotPasswordModal()
                }} className="pt-2 pr-3 ml-auto font-msb focus:outline-none text-dark-pink hover:text-light-pink">X</button>
                <p className="font-ab text-dark-pink text-lg">Email</p>
                <input className="font-ar text-black mt-1 pl-5 pr-5 pt-1 pb-1 focus:outline-none border-2
                    rounded-md border-grey focus:shadow-md w-5/6 md:w-2/4"
                    disabled={sending}
                    value={email}
                    placeholder="Ingres&aacute; tu email..."
                    onChange={(e) => setEmail(e.target.value)}
                    ref={emailInput} name="username" id="username"></input>
                <br></br>
                <ReCAPTCHA className="mt-5" ref={recaptcha} sitekey="6Lfo-y0aAAAAAPi51Dtv9tgWX55txrMjOcyBtQhV" onChange={captchaChanged}/>
                {error && !sending && <p className="font-abi text-dark-pink text-lg p-3 text-center">{error}</p>}
                {sending && <BeatLoader color="#FF7272" loading={sending} size={20}></BeatLoader>}
                <button disabled={!recaptchaValue} className={`font-msb  text-white hover:text-light-pink pt-2 pb-2 pr-10 pl-10
                                text-center align-middle mt-auto mb-4 focus:outline-none transition-colors duration-200
                                ${recaptchaValue ? 'cursor-pointer bg-dark-pink' : 'cursor-default bg-dark-grey'}
                                `} onClick={() => send()}>Enviar link de recuperaci&oacute;n</button>
                
            </div>
        </div>
    </>, document.getElementById("modal")) : <></>)
}